import { createRoutine } from 'redux-saga-routines';
import { createAction } from 'redux-actions';

import {
  APPROVE_ORDER,
  BLOCKING_ORDER,
  CHANGE_DIALOG_STATE,
  CHANGE_PRESCRIPTION_STATE,
  LOAD_DATA,
  REJECT_ORDER,
  DOWNLOAD_PDF,
  UNBLOCKING_ORDER,
  CHANGE_SYNC_STATE,
} from './types';

// Actions
export const changeDialogState = createAction(CHANGE_DIALOG_STATE);
export const changePrescriptionState = createAction(CHANGE_PRESCRIPTION_STATE);
export const changeSyncState = createAction(CHANGE_SYNC_STATE);

// Routines
export const approveOrder = createRoutine(APPROVE_ORDER);
export const rejectOrder = createRoutine(REJECT_ORDER);
export const detailOrder = createRoutine(LOAD_DATA);
export const pdfOrder = createRoutine(DOWNLOAD_PDF);
export const blockingOrder = createRoutine(BLOCKING_ORDER);
export const unblockingOrder = createRoutine(UNBLOCKING_ORDER);
