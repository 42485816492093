import produce from 'immer';
import {
  approveOrder,
  changeDialogState,
  changePrescriptionState,
  changeSyncState,
  detailOrder,
  rejectOrder,
} from './actions';

const initialState = {
  loading: false,
  alert: {
    show: false,
    message: '',
    color: 'primary',
  },
  emptyState: false,
  dialogs: {
    accept: false,
    reject: false,
    preview: false,
  },
  orderPrescriptionCapture: {
    refills: 0,
    directions: '',
    isSyncReview: false,
    isInvalidDirections: false,
  },
  order: {
    orders_id: null,
    orders_email: null,
    orders_doctegrity_id: null,
    orders_platform_order_id: null,
    orders_created_at: '',
    orders_status: 0,
    orders_order_type: 0,
    orders_product: null,
    orders_variant: null,
    orders_send_to_sign: 0,
    orders_signature_request_id: null,
    orders_is_complete: 0,
    orders_prescription_status: 'pending',
    orders_signer_email_address: null,
    orders_signing_url: null,
    orders_files_url: null,
    orders_fulfillment_status: null,
    orders_order_number: null,
    orders_financial_status: null,
    orders_is_woocommerce: 0,
    orders_onHoldPayment: 0,
    orders_reminder: 0,
    orders_skipVerification: 0,
    orders_stripe_id: null,
    orders_stripe_status: null,
    orders_wc_order_key: null,
    patients_id: 205,
    patients_gender: null,
    patients_cellphone: null,
    patients_state: null,
    patients_zipcode: null,
    patients_city: null,
    patients_shippingAddress: null,
    id: 0,
    order_number: null,
    status: 0,
    created_at: '',
    doctegrity_id: null,
    signature_request_id: null,
    full_name: null,
    product_name: null,
    signatureProvider: null,
    urlSignature: null,
    email: null,
    answers_json: {
      results: [],
    },
  },
};

export default produce((draft, { type, payload }) => {
  switch (type) {
    case changePrescriptionState.toString():
      draft.orderPrescriptionCapture = {
        ...draft.orderPrescriptionCapture,
        ...payload,
      };
      break;
    case changeSyncState.toString():
      draft.orderPrescriptionCapture = {
        ...draft.orderPrescriptionCapture,
        ...payload,
      };
      break;
    case changeDialogState.toString():
      draft.dialogs = { ...draft.dialogs, ...payload };
      break;
    case detailOrder.REQUEST:
      draft.loading = true;
      draft.order = initialState.order;
      draft.dialogs = initialState.dialogs;
      draft.orderPrescriptionCapture = initialState.orderPrescriptionCapture;
      draft.pdfDownload = initialState.pdfDownload;
      break;
    case detailOrder.SUCCESS: {
      const order = { ...payload };

      if (payload) {
        const { results } = order.answers_json;
        const ageFinded = results.find((e) => e.question_id === 'birthDate');

        if (ageFinded) {
          draft.order = {
            ...order,
            birth: ageFinded.value,
          };
        } else {
          draft.order = order;
        }

        draft.emptyState = false;
      } else {
        draft.emptyState = true;
      }

      break;
    }
    case detailOrder.FULFILL:
      draft.loading = false;
      break;
    case approveOrder.REQUEST:
      draft.loading = true;
      break;
    case approveOrder.FULFILL:
      draft.loading = false;
      break;
    case rejectOrder.REQUEST:
      draft.loading = true;
      break;
    case rejectOrder.FULFILL:
      draft.loading = false;
      break;
    // no default
  }
}, initialState);
