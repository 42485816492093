import { createRoutine } from 'redux-saga-routines';
import { createAction } from 'redux-actions';

import {
  LOAD_DATA,
  FILTER_DATA,
  TOTAL_ORDERS,
  LOAD_DOCTOR_LIST,
  CHANGE_MODAL_DATA,
  UPDATED_ORDER,
  UPDATE_DATA,
} from './types';

export const changeModalData = createAction(CHANGE_MODAL_DATA);
export const updatedOrder = createAction(UPDATED_ORDER);

export const updateData = createRoutine(UPDATE_DATA);
export const loadOrders = createRoutine(LOAD_DATA);
export const filter = createRoutine(FILTER_DATA);
export const loadOrdersTotal = createRoutine(TOTAL_ORDERS);
export const loadDoctorList = createRoutine(LOAD_DOCTOR_LIST);
