import React from 'react';
import checkIcon from 'assets/images/icon_check.svg';
import deniedIcon from 'assets/images/icon_close.svg';

export const options = {
  position: 'bottom-right',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

export const optionsIconFalse = {
  options,
  icon: false,
};

export const optionsImgStore = {
  options,
};

export const optionsOrder = {
  position: 'bottom-right',
  autoClose: 5000,
  closeButton: false,
  hideProgressBar: true,
  closeOnClick: false,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

export const successMessage = () => {
  return (
    <div className="d-flex justify-content-between align-items-center">
      <section>
        <p className="order-detail__feedback--title">Approved</p>
        <p className="order-detail__feedback--subtitle">
          The prescription has been approved.
        </p>
      </section>
      <img src={checkIcon} alt="Success Icon" />
    </div>
  );
};
export const errorMessage = () => {
  return (
    <div className="d-flex justify-content-between align-items-center">
      <section>
        <p className="order-detail__feedback--title">Denied</p>
        <p className="order-detail__feedback--subtitle">
          The prescription has been denied.
        </p>
      </section>
      <img src={deniedIcon} alt="Denied Icon" />
    </div>
  );
};
