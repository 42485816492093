import produce from 'immer';
import { setMe, retrieveMe } from './actions';

const initialState = {
  loading: false,
  item: {
    id: null,
    rol: {
      name: null,
      permissions: [],
    },
  },
  error: null,
};

export default produce((draft, { type, payload }) => {
  switch (type) {
    case setMe.toString():
    case retrieveMe.SUCCESS:
      draft.item = payload;
      break;
    case retrieveMe.REQUEST:
      draft.loading = true;
      draft.error = null;
      break;
    case retrieveMe.FAILURE:
      draft.error = payload;
      break;
    case retrieveMe.FULFILL:
      draft.loading = false;
      break;
    // no default
  }
}, initialState);
