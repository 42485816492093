/* eslint-disable no-useless-catch */
import SDK from 'utils/sdk';
import { all, call, put, takeLatest, select } from 'redux-saga/effects';
import { SubmissionError } from 'redux-form';

import { toast } from 'react-toastify';
import { optionsIconFalse, optionsOrder } from 'utils/toast-options';

import { setMe } from 'modules/me/actions';

import {
  authLogin,
  authGenerateTwoFactor,
  authValidateTwoFactor,
  authLogout,
  authForgotPassword,
  authCodeValidation,
  authNewPassword,
} from './actions';

import { getCodeValidation } from './selectors';

export function* authLoginSaga({ payload: { values: requestBody } }) {
  try {
    yield put(authLogin.request());
    const api = yield call([SDK, 'getApi']);
    const {
      body: { user, accessToken },
    } = yield call([api.auth, 'login'], {}, { requestBody });
    yield call([SDK, 'setToken'], accessToken);
    yield put(setMe(user));
    yield put(
      authLogin.success({
        accessToken,
        user,
      })
    );
  } catch (error) {
    if (error.response) {
      toast.error(error.response.body.message, optionsIconFalse);
    } else {
      toast.error(error.message, optionsIconFalse);
    }
    yield put(authLogin.failure(new SubmissionError({ _error: error })));
  } finally {
    yield put(authValidateTwoFactor.fulfill());
  }
}

export function* generateTwoFactorAuthSaga() {
  try {
    yield put(authGenerateTwoFactor.request());
    const api = yield call([SDK, 'getApi']);
    const tfa = api['two factor authentication'];
    const { data } = yield call([tfa, 'generateTwoFactorAuth']);
    yield put(authGenerateTwoFactor.success(URL.createObjectURL(data)));
  } catch (error) {
    yield put(authGenerateTwoFactor.failure(error));
  } finally {
    yield put(authGenerateTwoFactor.fulfill());
  }
}

export function* validateTwoFactorAuthSaga({
  payload: { values: requestBody },
}) {
  try {
    yield put(authValidateTwoFactor.request());
    const api = yield call([SDK, 'getApi']);
    const tfa = api['two factor authentication'];
    const { body } = yield call(
      [tfa, 'validateTwoFactorAuth'],
      {},
      { requestBody }
    );
    if (body.status === 401) {
      throw new Error(body.message);
    } else {
      yield call([SDK, 'setToken'], body.accessToken);
      yield put(setMe(body.user));
      yield put(authValidateTwoFactor.success(body));
    }
  } catch (error) {
    if (error.response) {
      toast.error(error.response.body.message, optionsIconFalse);
    } else {
      toast.error(error.message, optionsIconFalse);
    }
    yield put(
      authValidateTwoFactor.failure(new SubmissionError({ _error: error }))
    );
  } finally {
    yield put(authValidateTwoFactor.fulfill());
  }
}

export function* authLogoutSaga() {
  try {
    yield put(authLogout.request());
    yield call([SDK, 'setToken'], {});
    const socket = yield call([SDK, 'getSocket']);
    socket.disconnect();
    yield call([SDK, 'setSocket'], null);
    yield put(authLogout.success());
  } catch (error) {
    toast.error(error.message, optionsIconFalse);
    yield put(authLogout.failure(error.message));
  } finally {
    yield put(authLogout.fulfill());
  }
}

export function* authForgotPasswordSaga({ payload: { values: requestBody } }) {
  try {
    yield put(authForgotPassword.request());
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call(
      [api.auth, 'forgotPassword'],
      {},
      { requestBody }
    );
    yield put(authForgotPassword.success(body));
  } catch (error) {
    if (error.response) {
      toast.error(error.response.body.message, optionsIconFalse);
    } else {
      toast.error(error.message, optionsIconFalse);
    }
    yield put(
      authForgotPassword.failure(new SubmissionError({ _error: error }))
    );
  } finally {
    yield put(authForgotPassword.fulfill());
  }
}

export function* authCodeValidationSaga({ payload }) {
  try {
    yield put(authCodeValidation.request());
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call(
      [api.auth, 'verificationCode'],
      {},
      { requestBody: payload }
    );
    yield put(authCodeValidation.success(body));
  } catch (error) {
    if (error.response) {
      toast.error(error.response.body.message, optionsIconFalse);
    } else {
      toast.error(error.message, optionsIconFalse);
    }
    yield put(
      authCodeValidation.failure(new SubmissionError({ _error: error }))
    );
  } finally {
    yield put(authCodeValidation.fulfill());
  }
}

export function* authSetNewPassowrd({ payload: { values: requestBody } }) {
  try {
    yield put(authNewPassword.request());
    const id = yield select(getCodeValidation);
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call(
      [api.auth, 'newPassword'],
      { id },
      { requestBody }
    );
    yield put(authNewPassword.success(body));
    toast.success('Password changed', optionsOrder);
  } catch (error) {
    if (error.response) {
      toast.error(error.response.body.message, optionsIconFalse);
    } else {
      toast.error(error.message, optionsIconFalse);
    }
    yield put(authNewPassword.failure(new SubmissionError({ _error: error })));
  } finally {
    yield put(authNewPassword.fulfill());
  }
}

export default function* authWatch() {
  yield all([
    takeLatest(authLogin.TRIGGER, authLoginSaga),
    takeLatest(authGenerateTwoFactor.TRIGGER, generateTwoFactorAuthSaga),
    takeLatest(authValidateTwoFactor.TRIGGER, validateTwoFactorAuthSaga),
    takeLatest(authLogout.TRIGGER, authLogoutSaga),
    takeLatest(authForgotPassword.TRIGGER, authForgotPasswordSaga),
    takeLatest(authCodeValidation.TRIGGER, authCodeValidationSaga),
    takeLatest(authNewPassword.TRIGGER, authSetNewPassowrd),
  ]);
}
