import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { persistReducer } from 'redux-persist';
import { reducer as form } from 'redux-form';

import nav from 'modules/nav/reducer';
import auth from 'modules/auth/reducer';
import me from 'modules/me/reducer';
import dashboard from 'modules/dashboard/reducer';
import orderDetail from 'modules/order-detail/reducer';
import doctorOverview from 'modules/doctor-overview/reducer';
import doctorDetail from 'modules/doctor-detail/reducer';
import doctors from 'modules/doctors/reducer';
import history from './history';
import { authPersistConfig, rootPersistConfig } from './persistor';

const appReducer = combineReducers({
  // external
  router: connectRouter(history),
  form,

  // app
  nav,
  auth: persistReducer(authPersistConfig, auth),
  me,
  dashboard,
  orderDetail,
  doctorOverview,
  doctors,
  doctorDetail,
});

const actions = [];

const rootReducer = (state, action) => {
  if (actions.includes(action.type)) {
    return appReducer({}, action);
  }

  return appReducer(state, action);
};

export default persistReducer(rootPersistConfig, rootReducer);
