import produce from 'immer';
import {
  cleanDoctor,
  createDoctor,
  loadCountries,
  loadDoctorLicenses,
  loadDoctorProductsNotPermits,
  loadDoctorProductsPermits,
  loadDoctorUnlicensesStates,
  loadInfoDoctorDetail,
  loadLanguages,
  loadStates,
  loadZones,
  removeProductPermit,
  saveProductPermit,
  deleteLicenses,
  previewDoctor,
  deletePreviewDoctor,
  uploadDoctorSign,
  updateDoctorSignUrl,
} from './actions';

const initialState = {
  loading: false,
  doctor: {
    signUrlControl: false,
  },
  doctorLicenses: [],
  doctorUnlicensesStates: [],
  doctorProductsPermits: [],
  doctorProductsNotPermits: [],
  languages: [],
  zones: [],
  countries: [],
  states: [],
  preview: false,
};

export default produce((draft, { type, payload }) => {
  switch (type) {
    case updateDoctorSignUrl.toString():
      draft.doctor.signUrlControl = payload;
      break;
    case cleanDoctor.SUCCESS:
      draft.doctor = {};
      draft.doctorLicenses = [];
      draft.doctorProductsPermits = [];
      draft.doctorProductsNotPermits = [];
      break;
    case createDoctor.SUCCESS:
      draft.doctor = payload;
      break;
    case createDoctor.REQUEST:
      draft.loading = true;
      break;
    case createDoctor.FULFILL:
      draft.loading = false;
      break;
    case createDoctor.FAILURE:
      draft.error = payload;
      break;
    case loadInfoDoctorDetail.REQUEST:
      draft.loading = true;
      break;
    case loadInfoDoctorDetail.SUCCESS:
      draft.doctor = payload;
      draft.doctor.signUrlControl = payload.singFileUrl !== null;
      break;
    case loadInfoDoctorDetail.FAILURE:
      draft.error = payload;
      break;
    case loadInfoDoctorDetail.FULFILL:
      draft.loading = false;
      break;
    case loadLanguages.REQUEST:
      draft.loading = true;
      break;
    case loadLanguages.SUCCESS:
      draft.languages = payload;
      break;
    case loadLanguages.FAILURE:
      draft.error = payload;
      break;
    case loadLanguages.FULFILL:
      draft.loading = false;
      break;
    case loadZones.REQUEST:
      draft.loading = true;
      break;
    case loadZones.SUCCESS:
      draft.zones = payload;
      break;
    case loadZones.FAILURE:
      draft.error = payload;
      break;
    case loadZones.FULFILL:
      draft.loading = false;
      break;
    case loadCountries.REQUEST:
      draft.loading = true;
      break;
    case loadCountries.SUCCESS:
      draft.countries = payload;
      break;
    case loadCountries.FAILURE:
      draft.error = payload;
      break;
    case loadCountries.FULFILL:
      draft.loading = false;
      break;
    case loadStates.REQUEST:
      draft.loading = true;
      break;
    case loadStates.SUCCESS:
      draft.states = payload;
      break;
    case loadStates.FAILURE:
      draft.error = payload;
      break;
    case loadStates.FULFILL:
      draft.loading = false;
      break;
    case loadDoctorLicenses.REQUEST:
      draft.loading = true;
      break;
    case loadDoctorLicenses.SUCCESS:
      draft.doctorLicenses = payload;
      break;
    case loadDoctorLicenses.FAILURE:
      draft.error = payload;
      break;
    case loadDoctorLicenses.FULFILL:
      draft.loading = false;
      break;
    case loadDoctorProductsNotPermits.REQUEST:
      draft.loading = true;
      break;
    case loadDoctorProductsNotPermits.SUCCESS:
      draft.doctorProductsNotPermits = payload;
      break;
    case loadDoctorProductsNotPermits.FAILURE:
      draft.error = payload;
      break;
    case loadDoctorProductsNotPermits.FULFILL:
      draft.loading = false;
      break;
    case loadDoctorProductsPermits.REQUEST:
      draft.loading = true;
      break;
    case loadDoctorProductsPermits.SUCCESS:
      draft.doctorProductsPermits = payload;
      break;
    case loadDoctorProductsPermits.FAILURE:
      draft.error = payload;
      break;
    case loadDoctorProductsPermits.FULFILL:
      draft.loading = false;
      break;
    case loadDoctorUnlicensesStates.REQUEST:
      draft.loading = true;
      break;
    case loadDoctorUnlicensesStates.SUCCESS:
      draft.doctorUnlicensesStates = payload;
      break;
    case loadDoctorUnlicensesStates.FAILURE:
      draft.error = payload;
      break;
    case loadDoctorUnlicensesStates.FULFILL:
      draft.loading = false;
      break;
    case saveProductPermit.REQUEST:
      draft.loading = true;
      break;
    case saveProductPermit.SUCCESS:
      break;
    case saveProductPermit.FAILURE:
      draft.error = payload;
      break;
    case saveProductPermit.FULFILL:
      draft.loading = false;
      break;
    case removeProductPermit.REQUEST:
      draft.loading = true;
      break;
    case removeProductPermit.SUCCESS:
      break;
    case removeProductPermit.FAILURE:
      draft.error = payload;
      break;
    case removeProductPermit.FULFILL:
      draft.loading = false;
      break;
    case deleteLicenses.REQUEST:
      draft.loading = true;
      break;
    case deleteLicenses.SUCCESS:
      break;
    case deleteLicenses.FAILURE:
      draft.error = payload;
      break;
    case deleteLicenses.FULFILL:
      draft.loading = false;
      break;
    case previewDoctor.SUCCESS:
      draft.preview = true;
      break;
    case deletePreviewDoctor.SUCCESS:
      draft.preview = false;
      break;
    case uploadDoctorSign.REQUEST:
      draft.loading = true;
      break;
    case uploadDoctorSign.SUCCESS:
      draft.doctor.signUrlControl = true;
      break;
    case uploadDoctorSign.FAILURE:
      draft.error = payload;
      break;
    case uploadDoctorSign.FULFILL:
      draft.loading = false;
      break;
    // no default
  }
}, initialState);
