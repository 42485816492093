/* eslint-disable import/no-unresolved */
/* eslint-disable no-undef */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-useless-catch */
import SDK from 'utils/sdk';
import { call, put, takeLatest, select } from 'redux-saga/effects';
import { getFilters } from 'modules/nav/selectors';
import {
  loadOrders,
  loadOrdersTotal,
  loadDoctorList,
  updateData,
} from './actions';

function* tableDasboardSaga() {
  try {
    yield put(loadOrders.request());
    const paramsURL = yield select(getFilters);
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.dashboard, 'getAll'], paramsURL);
    yield put(loadOrders.success(body));
  } catch (error) {
    yield put(loadOrders.failure(error));
  } finally {
    yield put(loadOrders.fulfill());
  }
}
function* silentTableDasboardSaga() {
  try {
    yield put(updateData.request());
    const paramsURL = yield select(getFilters);
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.dashboard, 'getAll'], paramsURL);
    yield put(updateData.success(body));
    yield put(loadOrdersTotal());
  } catch (error) {
    yield put(updateData.failure(error));
  } finally {
    yield put(updateData.fulfill());
  }
}

function* ordersTotalSaga() {
  try {
    yield put(loadOrdersTotal.request());
    const paramsURL = yield select(getFilters);
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.orders, 'countByStatus'], paramsURL);
    yield put(loadOrdersTotal.success(body));
  } catch (error) {
    yield put(loadOrdersTotal.failure(error));
  } finally {
    yield put(loadOrders.fulfill());
  }
}

function* doctorList() {
  try {
    yield put(loadDoctorList.request());
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.dashboard, 'getAllDoctors'], {});
    yield put(loadDoctorList.success(body));
  } catch (error) {
    yield put(loadDoctorList.failure(error));
  } finally {
    yield put(loadDoctorList.fulfill());
  }
}

export default function* tableDasboardWatch() {
  yield takeLatest(loadOrders.TRIGGER, tableDasboardSaga);
  yield takeLatest(loadOrdersTotal.TRIGGER, ordersTotalSaga);
  yield takeLatest(loadDoctorList.TRIGGER, doctorList);
  yield takeLatest(updateData.TRIGGER, silentTableDasboardSaga);
}
