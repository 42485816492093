import SDK from 'utils/sdk';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { retrieveMe } from './actions';

export function* retrieveMeSaga() {
  try {
    yield put(retrieveMe.request());
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.users, 'UsersController_retrieveMe']);
    yield put(retrieveMe.success(body));
  } catch (error) {
    yield put(retrieveMe.failure(error));
  } finally {
    yield put(retrieveMe.fulfill());
  }
}

export default function* meWatch() {
  yield all([takeLatest(retrieveMe.TRIGGER, retrieveMeSaga)]);
}
