import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ConnectedRouter } from 'connected-react-router';
import { ToastContainer } from 'react-toastify';

import Routes from 'routes';
import routesConfig from 'routes/config';
import { store, persistor } from 'store';
import history from 'store/history';

import AbilityBuilder from 'utils/casl/ability';
import { AbilityContext } from 'views/components/casl';

import SdkLoader from 'views/components/sdk-loader';

const App = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <AbilityContext.Provider value={AbilityBuilder}>
        <SdkLoader>
          <ConnectedRouter history={history}>
            <Routes store={store} routes={routesConfig} />
            <ToastContainer />
          </ConnectedRouter>
        </SdkLoader>
      </AbilityContext.Provider>
    </PersistGate>
  </Provider>
);

export default App;
