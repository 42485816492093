/* eslint-disable import/no-unresolved */
import Loadable from 'react-loadable';
import {
  retrieveMe,
  authCodeValidation,
  authGenerateTwoFactor,
} from 'modules/auth/actions';
import {
  loadOrders,
  loadOrdersTotal,
  loadDoctorList,
} from 'modules/dashboard/actions';

import { loadDoctors } from 'modules/doctors/actions';
import { detailOrder } from 'modules/order-detail/actions';

import Loading from 'views/ui/loading/general-loading';

import {
  loadOrdersDoctorOverview,
  loadDoctorData,
  loadDoctorLicences,
  loadDoctorProducts,
  loadOrdersTotalOverview,
} from 'modules/doctor-overview/actions';

import {
  cleanDoctor,
  loadCountries,
  loadDoctorLicenses,
  loadDoctorProductsNotPermits,
  loadDoctorProductsPermits,
  loadDoctorUnlicensesStates,
  loadInfoDoctorDetail,
  loadLanguages,
  loadStates,
  loadZones,
} from 'modules/doctor-detail/actions';

const Login = Loadable({
  loader: () => import('views/screens/login'),
  loading: () => null,
});

const TwoAuthentication = Loadable({
  loader: () => import('views/screens/two-authentication'),
  loading: () => null,
});

const LayoutDashboard = Loadable({
  loader: () => import('views/containers/layout-dashboard'),
  loading: Loading,
});

const Dashboard = Loadable({
  loader: () => import('views/screens/dashboard'),
  loading: Loading,
});

const DoctorOverview = Loadable({
  loader: () => import('views/screens/doctors-overview'),
  loading: Loading,
});

const DoctorsTable = Loadable({
  loader: () => import('views/screens/dashboard-doctor'),
  loading: Loading,
});

const OrderDetail = Loadable({
  loader: () => import('views/screens/order-detail'),
  loading: Loading,
});
const RestorePassword = Loadable({
  loader: () => import('views/screens/password-restore'),
  loading: Loading,
});
const HelpRestorePassword = Loadable({
  loader: () => import('views/screens/password-help'),
  loading: Loading,
});
const SearchEmail = Loadable({
  loader: () => import('views/screens/password-send'),
  loading: Loading,
});
const ChangePassword = Loadable({
  loader: () => import('views/screens/password-change'),
  loading: Loading,
});
const PasswordChange = Loadable({
  loader: () => import('views/screens/password-success'),
  loading: Loading,
});
const DoctorDetail = Loadable({
  loader: () => import('views/screens/doctors-detail'),
  loading: Loading,
});
const DoctorNew = Loadable({
  loader: () => import('views/screens/doctors-new'),
  loading: Loading,
});

const routes = [
  {
    path: '/',
    component: Login,
    exact: true,
    published: true,
  },
  {
    path: '/two_authentication',
    component: TwoAuthentication,
    actions: [authGenerateTwoFactor],
    exact: true,
  },
  {
    path: '/restore_password',
    component: RestorePassword,
    exact: true,
    published: true,
  },
  {
    path: '/restore_password/seach_email',
    component: SearchEmail,
    exact: true,
    published: true,
  },
  {
    path: '/restore_password/help',
    component: HelpRestorePassword,
    exact: true,
    published: true,
  },
  {
    path: '/change_password/:code',
    component: ChangePassword,
    actions: [authCodeValidation],
    exact: true,
    published: true,
  },
  {
    path: '/password_change',
    component: PasswordChange,
    exact: true,
    published: true,
  },
  {
    path: '/app',
    component: LayoutDashboard,
    privated: true,
    actions: [retrieveMe],
    routes: [
      {
        path: '/app',
        component: Dashboard,
        actions: [loadOrders, loadOrdersTotal, loadDoctorList],
        exact: true,
        defaultFilters: {
          order: 'DESC',
          page: 1,
          search: '',
          doctorId: '',
          sort: 'created_at',
          createdAtFrom: '',
          createdAtTo: '',
          status: 1,
        },
      },
      {
        path: '/app/doctors',
        component: DoctorsTable,
        actions: [loadDoctors],
        exact: true,
        defaultFilters: {
          order: 'DESC',
          page: 1,
          search: '',
          sort: 'created_at',
          createdAtFrom: '',
          createdAtTo: '',
        },
      },
      {
        path: '/app/doctors/overview/:id',
        actions: [
          loadOrdersDoctorOverview,
          loadDoctorData,
          loadDoctorLicences,
          loadDoctorProducts,
          loadOrdersTotalOverview,
        ],
        component: DoctorOverview,
        defaultFilters: {
          order: 'DESC',
          page: 1,
          search: '',
          sort: 'created_at',
          status: 2,
        },
      },
      {
        path: '/app/doctors/new',
        component: DoctorNew,
        actions: [
          cleanDoctor,
          loadLanguages,
          loadZones,
          loadStates,
          loadCountries,
        ],
      },
      {
        path: '/app/doctors/edit/:id',
        component: DoctorDetail,
        actions: [
          cleanDoctor,
          loadInfoDoctorDetail,
          loadDoctorUnlicensesStates,
          loadDoctorLicenses,
          loadDoctorProductsPermits,
          loadDoctorProductsNotPermits,
          loadLanguages,
          loadZones,
          loadStates,
          loadCountries,
        ],
      },
      {
        path: '/app/orders/:orderId',
        component: OrderDetail,
        exact: true,
        actions: [detailOrder],
      },
    ],
  },
  {
    name: '404',
    path: '*',
    component: () => null,
  },
];

export default routes;
