/* eslint-disable no-case-declarations */
import produce from 'immer';
import { totalPage } from 'utils/pagination/current-or-total-page-value';

import {
  loadOrders,
  loadOrdersTotal,
  loadDoctorList,
  changeModalData,
  updatedOrder,
  updateData,
} from './actions';

const initialState = {
  loading: false,
  error: null,
  meta: {
    count: 0,
    currentPage: null,
    totalPages: 0,
    hasPreviousPage: null,
    hasNextPage: null,
  },
  modals: {
    dateRange: false,
    openModalQr: false,
  },
  counterOrders: 0,
  orders: [],
  doctors: [],
  countByStatus: {},
};

export default produce((draft, { type, payload }) => {
  switch (type) {
    case loadOrders.REQUEST:
      draft.loading = true;
      break;
    case loadOrders.SUCCESS:
    case updateData.SUCCESS:
      draft.orders = [...payload.orders];
      if (payload.orders.length > 0) {
        draft.meta.currentPage = payload.pagination.currentPage;
        draft.meta.totalPages = totalPage(payload.count);
        draft.meta.hasPreviousPage = draft.meta.currentPage !== 1;
        draft.meta.hasNextPage =
          draft.meta.currentPage !== draft.meta.totalPages;
      }
      break;
    case loadOrders.FAILURE:
      draft.error = payload;
      break;
    case updateData.FAILURE:
      draft.error = payload;
      break;
    case loadOrders.FULFILL:
      draft.loading = false;
      break;
    case loadOrdersTotal.REQUEST:
      draft.loading = true;
      break;
    case loadOrdersTotal.SUCCESS:
      draft.countByStatus = { ...payload };
      break;
    case loadOrdersTotal.FAILURE:
      draft.error = payload;
      break;
    case loadOrdersTotal.FULFILL:
      draft.loading = false;
      break;
    case loadDoctorList.REQUEST:
      draft.loading = true;
      break;
    case loadDoctorList.SUCCESS:
      draft.doctors = payload;
      break;
    case loadDoctorList.FAILURE:
      draft.error = payload;
      break;
    case loadDoctorList.FULFILL:
      draft.loading = false;
      break;
    case changeModalData.toString():
      draft.modals = { ...draft.modals, ...payload };
      break;
    case updatedOrder.toString():
      draft.orders = draft.orders.map((item) => {
        return payload.blockedOrders.includes(item.id)
          ? { ...item, orders_blocking: true }
          : { ...item, orders_blocking: false, orders_blocking_user: null };
      });

      break;
    // no default
  }
}, initialState);
