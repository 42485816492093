import SDK from 'utils/sdk';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  optionsOrder,
  optionsIconFalse,
  successMessage,
  errorMessage,
} from 'utils/toast-options';
import { toast } from 'react-toastify';

import {
  detailOrder,
  approveOrder,
  rejectOrder,
  changeDialogState,
  pdfOrder,
  blockingOrder,
  unblockingOrder,
} from './actions';

function* getOrderByIdSaga({ payload: { orderId } }) {
  try {
    yield put(detailOrder.request());
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.orders, 'getOrder'], {
      orderId,
    });
    if (body.orders_prescription_status === 'pending') {
      yield put(blockingOrder(body));
    }
    yield put(detailOrder.success(body));
  } catch (error) {
    yield put(detailOrder.failure(error));
  } finally {
    yield put(detailOrder.fulfill());
  }
}

function* approveOrderByIdSaga({ payload }) {
  try {
    yield put(approveOrder.request());
    const api = yield call([SDK, 'getApi']);
    const socket = yield call([SDK, 'getSocket']);
    const { body } = yield call(
      [api.orders, 'acceptPrescription'],
      {},
      { requestBody: { ...payload, socketId: socket.id } }
    );

    toast.success(successMessage, optionsOrder);
    yield put(approveOrder.success(body));
    yield put(detailOrder({ orderId: body.orderId }));
  } catch (error) {
    toast.error('Ops! Something was wrong!', optionsIconFalse);
    yield put(approveOrder.failure(error));
  } finally {
    yield put(approveOrder.fulfill());
    yield put(changeDialogState({ accept: false }));
  }
}

function* rejectOrderByIdSaga({ payload }) {
  try {
    yield put(rejectOrder.request());
    const api = yield call([SDK, 'getApi']);
    const socket = yield call([SDK, 'getSocket']);
    const { body } = yield call(
      [api.orders, 'rejectPrescription'],
      {},
      { requestBody: { ...payload, socketId: socket.id } }
    );

    yield put(rejectOrder.success());
    toast.error(errorMessage, optionsOrder);
    yield put(detailOrder({ orderId: body.orderId }));
  } catch (error) {
    toast.error('Ops! Something was wrong!', optionsIconFalse);
    yield put(rejectOrder.failure(error));
  } finally {
    yield put(rejectOrder.fulfill());
    yield put(changeDialogState({ reject: false }));
  }
}

function* getOrderPdfSaga({ payload: { orderId } }) {
  try {
    yield put(pdfOrder.request());
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.orders, 'getOrderPdf'], {
      orderId,
    });
    const a = window.document.createElement('a');
    a.href = body;
    a.download = 'new_document.pdf';
    a.click();
    a.remove();
    yield put(pdfOrder.success());
  } catch (error) {
    yield put(pdfOrder.failure(error));
  } finally {
    yield put(pdfOrder.fulfill());
  }
}

export function* blockingOrderSaga({ payload }) {
  try {
    const { id } = payload;
    yield put(blockingOrder.request());
    const api = yield call([SDK, 'getApi']);
    const socket = yield call([SDK, 'getSocket']);
    yield call(
      [api.orders, 'blocking'],
      { id },
      { requestBody: { blocking: true, socketId: socket.id } }
    );
    yield put(blockingOrder.success());
  } catch (error) {
    yield put(blockingOrder.failure(error));
    toast.error('Ops! Something was wrong!', optionsIconFalse);
  } finally {
    yield put(blockingOrder.fulfill());
  }
}

export function* unBlockingOrderSaga({ payload: { orderId, status } }) {
  try {
    yield put(unblockingOrder.request());
    if (status === 'pending') {
      const api = yield call([SDK, 'getApi']);
      const socket = yield call([SDK, 'getSocket']);
      yield call(
        [api.orders, 'blocking'],
        { id: orderId },
        { requestBody: { blocking: false, socketId: socket.id } }
      );
    }
    yield put(unblockingOrder.success());
  } catch (error) {
    yield put(unblockingOrder.failure(error));
    toast.error('Ops! Something was wrong!', optionsIconFalse);
  } finally {
    yield put(unblockingOrder.fulfill());
  }
}

export default function* orderDetailWatch() {
  yield takeLatest(detailOrder.TRIGGER, getOrderByIdSaga);
  yield takeLatest(approveOrder.TRIGGER, approveOrderByIdSaga);
  yield takeLatest(rejectOrder.TRIGGER, rejectOrderByIdSaga);
  yield takeLatest(pdfOrder.TRIGGER, getOrderPdfSaga);
  yield takeLatest(blockingOrder.TRIGGER, blockingOrderSaga);
  yield takeLatest(unblockingOrder.TRIGGER, unBlockingOrderSaga);
}
