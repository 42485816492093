import SDK from 'utils/sdk';
import { call, put, takeLatest, select } from 'redux-saga/effects';
import { getFilters } from 'modules/nav/selectors';
import {
  loadDoctorData,
  loadDoctorLicences,
  loadDoctorProducts,
  loadOrdersDoctorOverview,
  loadOrdersTotalOverview,
} from './actions';

function* doctorOverviewOrdersSaga({ payload: { id } }) {
  try {
    yield put(loadOrdersDoctorOverview.request());
    const paramsURL = yield select(getFilters);
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.dashboard, 'getAll'], {
      doctorId: id,
      ...paramsURL,
    });
    yield put(loadOrdersDoctorOverview.success(body));
  } catch (error) {
    yield put(loadOrdersDoctorOverview.failure(error));
  } finally {
    yield put(loadOrdersDoctorOverview.fulfill());
  }
}

function* doctorDataSaga({ payload: { id } }) {
  try {
    yield put(loadDoctorData.request());
    const paramsURL = yield select(getFilters);
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.users, 'getDoctor'], {
      id,
      ...paramsURL,
    });
    yield put(loadDoctorData.success(body));
  } catch (error) {
    yield put(loadDoctorData.failure(error));
  } finally {
    yield put(loadDoctorData.fulfill());
  }
}

function* doctorLicencesSaga({ payload: { id } }) {
  try {
    yield put(loadDoctorLicences.request());
    const paramsURL = yield select(getFilters);
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.users, 'getDoctorLicences'], {
      id,
      ...paramsURL,
    });
    yield put(loadDoctorLicences.success(body));
  } catch (error) {
    yield put(loadDoctorLicences.failure(error));
  } finally {
    yield put(loadDoctorLicences.fulfill());
  }
}

function* doctorProductsSaga({ payload: { id } }) {
  try {
    yield put(loadDoctorProducts.request());
    const paramsURL = yield select(getFilters);
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.users, 'getDoctorProducts'], {
      id,
      ...paramsURL,
    });
    yield put(loadDoctorProducts.success(body));
  } catch (error) {
    yield put(loadDoctorProducts.failure(error));
  } finally {
    yield put(loadDoctorProducts.fulfill());
  }
}

function* doctorOverviewOrdersTotalSaga({ payload: { id } }) {
  try {
    yield put(loadOrdersTotalOverview.request());
    const paramsURL = yield select(getFilters);
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.orders, 'countByStatus'], {
      doctorId: id,
      ...paramsURL,
    });
    yield put(loadOrdersTotalOverview.success(body));
  } catch (error) {
    yield put(loadOrdersTotalOverview.failure(error));
  } finally {
    yield put(loadOrdersTotalOverview.fulfill());
  }
}

export default function* doctorOverviewWatch() {
  yield takeLatest(loadOrdersDoctorOverview.TRIGGER, doctorOverviewOrdersSaga);
  yield takeLatest(loadDoctorData.TRIGGER, doctorDataSaga);
  yield takeLatest(loadDoctorLicences.TRIGGER, doctorLicencesSaga);
  yield takeLatest(loadDoctorProducts.TRIGGER, doctorProductsSaga);
  yield takeLatest(
    loadOrdersTotalOverview.TRIGGER,
    doctorOverviewOrdersTotalSaga
  );
}
